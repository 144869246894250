import React, { useEffect, useState } from 'react'
import { LanguageSelector } from 'ui'
import i18n from '../locales/i18n'
import { t } from 'i18next'
import i18next from 'i18next'
import { useContext } from 'react'
import { useTreatments, SplitContext } from '@splitsoftware/splitio-react'

const ChangeLanguage = (props) => {
  const [isFinishActive, setIsFinishActive] = useState(false)
  const [isSwedishActive, setIsSwedishActive] = useState(false)
  const selectedLang = i18next.language
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  const finishFeature = 'ENABLE_FINISH_LANGUAGE'
  const swedishFeature = 'SWEDISH_LANGUAGE_IN_DEBTOR_PORTAL'

  const { isReady } = useContext(SplitContext)
  const treatments = useTreatments([finishFeature, swedishFeature])

  useEffect(() => {
    setIsFinishActive(treatments[finishFeature].treatment === 'on')
    setIsSwedishActive(treatments[swedishFeature].treatment === 'on')
  }, [treatments])

  const availableLanguages = [
    {
      title: 'norwegian',
      lang: 'no',
      action(lang) {
        changeLanguage(lang)
      }
    },
    {
      title: 'english',
      lang: 'en',
      action(lang) {
        changeLanguage(lang)
      }
    }
  ]

  if (isReady) {
    if (isFinishActive) {
      availableLanguages.push({
        title: 'finnish',
        lang: 'fi',
        action(lang) {
          changeLanguage(lang)
        }
      })
    }
    if (isSwedishActive) {
      availableLanguages.push({
        title: 'swedish',
        lang: 'sv',
        action(lang) {
          changeLanguage(lang)
        }
      })
    }
  }

  return (
    <div className="header__change-language" {...props}>
      <LanguageSelector
        availableLanguages={availableLanguages}
        selectedLanguage={selectedLang}
        callback={({ i18n, selectedLanguage }) => {
          i18n.changeLanguage(selectedLanguage)
        }}
        placement={'bottom'}
        t={t}
        className={'LanguageSelector'}
      />
    </div>
  )
}

export default ChangeLanguage
