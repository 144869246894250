import axios from 'axios'
import isBoolean from 'lodash/isBoolean'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'

export const instance = axios.create()

// Reset default portal authorization header
instance.defaults.headers.common = {}

const handleErrors = (errors) => {
  throw errors
}

const parseBody = (response) => {
  if (response.data.errors) {
    throw response.data.errors
  }

  const data = response.data.data

  const parentObject = data[Object.keys(data)[0]]

  // Server's response is an object whose only key is the operation name
  return parentObject
}

export const setQueryVariables = (query, variables) => {
  let queryWithParams = query

  if (variables) {
    Object.keys(variables).forEach((variable) => {
      const value =
        isString(variables[variable]) ||
        isNumber(variables[variable]) ||
        isBoolean(variables[variable])
          ? variables[variable]
          : JSON.stringify(variables[variable])

      // Regex to search for the name of the variable if it's not followed by a letter
      const regex = new RegExp(`(\\$${variable})([^a-zA-Z])`, 'g')

      queryWithParams = queryWithParams.replace(regex, `${value}$2`)
    })
  }

  return queryWithParams
}

export const client = {
  query: async (query, options) => {
    const variables = options.variables

    const queryWithParams = setQueryVariables(query, variables)

    return instance
      .post(
        options.url,
        {
          query: queryWithParams
        },
        {
          headers: {
            token: options.token,
            version: options.version
          }
        }
      )
      .then(parseBody)
      .catch(handleErrors)
  }
}
