import React, { useEffect, useState } from 'react'
import useConfig from './hooks/useConfig'
import App from 'App'
import { IntercomProvider } from 'react-use-intercom'
import TagManager from 'react-gtm-module'
import { setupAxios } from 'utils/axios'
import { SplitFactory } from '@splitsoftware/splitio-react'

const KraviaApp = ({ render }) => {
  return render && <App />
}

export default function Root(_) {
  const [isConfigLoaded, config] = useConfig()
  const [render, setRender] = useState(false)

  useEffect(() => {
    if (isConfigLoaded) {
      setupAxios(config.debtor_api.url)
      const measurementId = config.google_tag_manager.tag
      const tagManagerArgs = {
        gtmId: measurementId
      }
      TagManager.initialize(tagManagerArgs)
      setRender(true)
    }
  }, [isConfigLoaded])

  const sdkConfig = {
    core: {
      authorizationKey: config?.split.key,
      key: 'xxxx'
    }
  }

  return (
    isConfigLoaded && (
      <IntercomProvider appId={isConfigLoaded ? config.intercom.appId : ''}>
        <SplitFactory config={sdkConfig}>
          <KraviaApp render={render} />
        </SplitFactory>
      </IntercomProvider>
    )
  )
}
