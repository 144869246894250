import axios from 'axios'

export const setupAxios = (baseUrl) => {
  axios.defaults.baseURL = baseUrl

  axios.defaults.headers.common = {
    'Accept-Language': ['en', 'no'],
    'Content-Type': 'application/json'
  }
}
