import { t } from 'i18next'
import React from 'react'
import useNavWithQuery from '../hooks/navigation/useNavWithQuery'

const Footer = () => {
  let d = new Date()
  let year = d.getFullYear()
  const navWithQuery = useNavWithQuery()

  return (
    <footer className="footer clearfix">
      <p className="footer__text">
        Copyright &copy; {year} Kravia AS{' '}
        <a onClick={() => navWithQuery('/privacy-policy')}>
          {t('header.privacy_policy')}
        </a>
      </p>
    </footer>
  )
}

export default Footer
