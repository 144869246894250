import { t } from 'i18next'
import React from 'react'
import { StyledButton } from 'ui'

const ActionButtons = (props) => {
  const [searchParams, setSearchParams] = props.useSearchParams()
  return (
    <>
      <div className="button-wrapper">
        <div className="intercom-buttons">
          <StyledButton
            color="primary"
            label={t('details.have_paid')}
            className="intercom-buttons__button custom-default-button"
            id="claim-action-button-already-paid"
            onClick={() => {
              const newSearchParams = new URLSearchParams(searchParams)
              newSearchParams.set('action', 'alreadypaid')
              setSearchParams(newSearchParams)
              sa_event('click_already_paid')
            }}
          />

          <StyledButton
            color="primary"
            label={t('details.disagree')}
            className="intercom-buttons__button custom-default-button"
            id="claim-action-button-dispute"
            onClick={() => {
              const newSearchParams = new URLSearchParams(searchParams)
              newSearchParams.set('action', 'dispute')
              setSearchParams(newSearchParams)
              sa_event('click_dispute')
            }}
          />
          <StyledButton
            color="primary"
            label={t('details.cant_pay_now')}
            className="intercom-buttons__button custom-default-button"
            id="claim-action-button-pay-later"
            onClick={() => {
              const newSearchParams = new URLSearchParams(searchParams)
              newSearchParams.set('action', 'paylater')
              setSearchParams(newSearchParams)
              sa_event('click_pay_later')
            }}
          />
        </div>
      </div>
    </>
  )
}

export default ActionButtons
