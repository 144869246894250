import { yupResolver } from '@hookform/resolvers/yup'
import Tippy from '@tippyjs/react'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { HelpModal, Icons, Modal, StyledButton } from 'ui'
import { zipCodeSchemas } from '../constants/validation-schemas'
import AuthContext from '../context/AuthContext'
import { GET_HELP_POPUP_ITEMS } from '../data/cms/HelpPopup/HelpPopupItems'
import { useContent } from '../hooks/cms/use-content'
import checkError from '../utils/checkError'
import { getDefaultCountryCode } from '../utils/country'
import { removeSuffix } from '../utils/removeSuffix'
import { setBodyColor } from '../utils/setBodyColor'

import 'tippy.js/animations/scale.css'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import useNavWithQuery from '../hooks/navigation/useNavWithQuery'
import '../scss/tooltip.scss'

const Verify = ({ brandInfo, isBrandFetched }) => {
  useEffect(() => {
    if (isBrandFetched) {
      setBodyColor({ color: brandInfo?.background_color ?? '#e4e3f2' })
    }
  }, [isBrandFetched])
  const { t, i18n } = useTranslation()
  const navWithQuery = useNavWithQuery()
  const { id } = useParams()
  const [submitting, setSubmitting] = useState(false)
  const [_, setAuth] = useContext(AuthContext)
  const [authError, setAuthError] = useState(null)
  const caseNumber = removeSuffix(id)
  const [modalOpened, setModalOpened] = useState(null)

  const [searchParams] = useSearchParams()

  const link = searchParams.get('link')
  const countryCode = searchParams.get('countryCode') || getDefaultCountryCode()

  useEffect(() => {
    const preAuthorize = async () => {
      setSubmitting(true)
      try {
        await axios.post(`/authorize`, {
          username: caseNumber,
          operationCountryCode: countryCode
        })
      } catch (error) {
        setAuthError(checkError(error.response.data.errorType))
        navWithQuery('/', { params: { caseNotFound: true } })
      } finally {
        setSubmitting(false)
      }
    }

    if (!link || link === 'true') {
      preAuthorize()
    }
  }, [caseNumber])

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(zipCodeSchemas)
  })

  const onSubmit = async (data) => {
    sa_event('click_next_verify')
    setSubmitting(true)
    setAuthError(null)
    try {
      const body = {
        username: caseNumber,
        password: data.zipCode,
        grantType: 'PostalCode',
        operationCountryCode: countryCode
      }

      const response = await axios.post(`/oauth/token`, body)

      if (response.data.successful === true) {
        setAuth(response.data.value.accessToken)
        navWithQuery(`/case/${caseNumber}`, {
          params: { countryCode: countryCode }
        })
      }
    } catch (error) {
      setAuthError(checkError(error.response.data.errorType))
    } finally {
      setSubmitting(false)
    }
  }

  const goBack = () => {
    navWithQuery('/')
  }

  const slug = 'login/my-postal-code-doesn-t-work'

  const postalCodeNotWorking = useContent(GET_HELP_POPUP_ITEMS, {
    language: i18n.language,
    slug: slug
  })

  const isLoading = postalCodeNotWorking.isLoading || !postalCodeNotWorking.data

  useEffect(() => {
    !isLoading && postalCodeNotWorking.refetch()
  }, [i18n.language])

  return (
    <div className="login-wrapper">
      {modalOpened && (
        <>
          {!isLoading && (
            <Modal
              animation="slide-up"
              key="confirmation_modal"
              position="center-center"
              onClose={() => {
                setModalOpened(null)
              }}
              backdropColor={'rgba(65,71,155,0.6'}
            >
              <HelpModal
                onClose={() => {
                  setModalOpened(null)
                }}
                data={postalCodeNotWorking?.data}
                type={'warning'}
              />
            </Modal>
          )}
        </>
      )}
      <div className="login-box">
        <div className="login-box__wrapper">
          <div>
            <div className="login-box__section">
              <h1 className="login-box__title">{t('verify.title')}</h1>
              <p className="login-box__description">
                {t('verify.description')}
                <Tippy
                  placement="bottom"
                  theme="info"
                  arrow={false}
                  trigger="click"
                  animation="scale"
                  interactive={true}
                  content={
                    <div style={{ padding: '8px', maxWidth: '246px' }}>
                      {t('help.postal_code_explanation')}
                    </div>
                  }
                >
                  <span
                    style={{
                      marginLeft: '5px',
                      cursor: 'pointer',
                      color: '#43BBCE'
                    }}
                  >
                    <Icons.InfoBold width={15} style={{ display: 'block' }} />
                  </span>
                </Tippy>
              </p>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="loginForm"
                id="form"
              >
                <fieldset disabled={submitting} className="fieldset">
                  <input
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    autoFocus
                    {...register('zipCode', {
                      onChange: () => setAuthError(null)
                    })}
                    className={
                      errors.zipCode
                        ? 'login-box__input-verify errorInput'
                        : 'login-box__input-verify'
                    }
                    placeholder={t('verify.input_placeholder')}
                    onFocus={(e) => (e.target.placeholder = '')}
                    onBlur={(e) =>
                      (e.target.placeholder = t('verify.input_placeholder'))
                    }
                  />
                  {authError && <p className="error-label">{t(authError)}</p>}
                  {errors.zipCode && (
                    <p className="error-label">{t(errors.zipCode.message)}</p>
                  )}
                </fieldset>
              </form>
            </div>
            <div className="button-groupe">
              <div className="action-button" style={{ marginRight: '10px' }}>
                <StyledButton
                  className="action-button__title"
                  label={t('common.back_button')}
                  color="primary"
                  onClick={goBack}
                />
              </div>
              <div className="action-button">
                <StyledButton
                  className="action-button__title"
                  label={t('common.confirm')}
                  type="submit"
                  form="form"
                  color="orange"
                  customColor={brandInfo?.action_button_color}
                  loading={submitting}
                />
              </div>
            </div>
            <div className="help-section">
              <div className="help-section__header">{t('help.header')}</div>
              <div
                className="help-section__list"
                onClick={() => {
                  setModalOpened(true)
                  sa_event(`open_inline_help`, { slug: slug })
                }}
              >
                <Icons.Arrow width={14} style={{ marginRight: '14px' }} />{' '}
                <span className="help-section__list-text">
                  {t('help.postal_code_not_working')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Verify
