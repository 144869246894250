import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { LanguageDetector } from 'ui'

import TRANSLATIONS_NO from './no/translations.json'
import TRANSLATIONS_EN from './en/translations.json'
import TRANSLATIONS_FI from './fi/translations.json'
import TRANSLATIONS_SV from './sv/translations.json'

const resources = {
  en: {
    translation: TRANSLATIONS_EN
  },
  no: {
    translation: TRANSLATIONS_NO
  },
  fi: {
    translation: TRANSLATIONS_FI
  },
  sv: {
    translation: TRANSLATIONS_SV
  }
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    supportedLngs: Object.keys(resources),
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
