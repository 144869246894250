export const environment = {
  debtor_api: {
    url: 'https://debtor-api-dev.kravia.ai'
  },
  single_spa_proxy: {
    url: 'https://kraviadevappsweb.z16.web.core.windows.net'
  },
  split: {
    key: 'aaqgenj43v0kbi174sk4qc1ate8n0pk6rios'
  },
  google_tag_manager: {
    tag: 'GTM-5VLMZWC'
  },
  intercom: {
    appId: 'vyitg90i'
  },
  storyblokConfig: {
    url: 'https://gapi.storyblok.com/v1/api',
    token: '7ZomgFcOulP6JhCjqlFJYQtt',
    version: 'draft'
  }
}
