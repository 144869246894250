const checkError = (errorType) => {
  let errorMessage
  switch (errorType) {
    case 'AccessDenied':
      errorMessage = 'errors.general.access_denied'
      break
    case 'ClaimNotFound':
      errorMessage = 'errors.general.not_found'
      break
    case 'InternalError':
      errorMessage = 'errors.default'
      break
    case 'Logic':
      errorMessage = 'errors.default'
      break
    default:
      errorMessage = 'errors.general.session_expired'
  }

  return errorMessage
}

export default checkError
