import React from 'react'
import { Spinner } from 'ui'

const Loader = () => {
  return (
    <div className="full-screen">
      <Spinner className="logo" size={45} />
    </div>
  )
}

export default Loader
