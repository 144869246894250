export const GET_BRAND_ITEMS = `
query {
  DebtorbrandItems(starts_with: "$language/") {
    items {
        slug,
        content {
            brand
            domain
            logo {
                filename
                alt
            }
            primary_color
            action_button_color
            background_color
            primary_text_color
            contact_background_color
            contact_text_color
            contacts {
                content
            }
        }
    }
}
}
`
