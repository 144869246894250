import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from '../context/AuthContext'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

function withProviders(Component) {
  return function ProviderWrappedComponent(props) {
    return (
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BrowserRouter>
            <Component {...props} />
          </BrowserRouter>
        </AuthProvider>
      </QueryClientProvider>
    )
  }
}

export default withProviders
