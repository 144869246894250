import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

const useNavWithQuery = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (to, { replace = false, state = {}, params = {} } = {}) => {
    const currentSearchParams = new URLSearchParams(location.search)
    const brandParam = currentSearchParams.get('brand')
      ? { brand: currentSearchParams.get('brand') }
      : {}
    const newParams = { ...params }
    const newSearchParams = createSearchParams({ ...newParams, ...brandParam })
    navigate(`${to}?${newSearchParams}`, { replace, state })
  }
}

export default useNavWithQuery
