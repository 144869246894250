import React, { useState } from 'react'
import RichTextResolver from 'storyblok-js-client/richTextResolver'
import { AnimatePresence, motion } from 'framer-motion'
import { Icons } from 'ui'

export const QuestionBlock = ({ data }) => {
  const info = data?.items
  const [isOpened, setIsOpened] = useState(false)
  const handleToggle = (key) => {
    setIsOpened(isOpened !== key ? key : null)
  }

  return (
    <div className="questions__block">
      {info?.map(({ content, full_slug }) => {
        const answer = content?.answer
        const resolver = new RichTextResolver()
        const html = resolver.render(answer)
        const open = isOpened === full_slug
        return (
          <div key={full_slug} className="questions__item">
            <div
              className="questions__item--title"
              data-opened={open}
              onClick={() => handleToggle(full_slug)}
            >
              {content?.question}
              <div className="questions__item--icon" data-opened={open}>
                <Icons.Hide width={16} />
              </div>
            </div>
            <AnimatePresence initial={false}>
              <motion.div
                className="questions__item--animate-wrapper"
                animate={open ? 'opened' : 'closed'}
                variants={{
                  opened: { height: 'auto' },
                  closed: { height: '0px' }
                }}
                transition={{
                  ease: 'easeOut',
                  duration: 0.25
                }}
              >
                <div
                  className="questions__item--answer"
                  dangerouslySetInnerHTML={{ __html: html }}
                  data-opened={open}
                />
              </motion.div>
            </AnimatePresence>
          </div>
        )
      })}
    </div>
  )
}
