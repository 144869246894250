import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'
import { styled } from '@mui/material/styles'
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Icons, StyledButton, formatMoney } from 'ui'
import useNavWithQuery from '../hooks/navigation/useNavWithQuery'
import { getDate } from '../utils/getDate'
import ActionButtons from './ActionButtons'
import CaseStatus from './CaseStatus'

export const caseStatus = {
  invoice: 'Invoice',
  open: 'Open',
  closed: 'Closed',
  withdraw: 'Withdrawn'
}

export const caseState = {
  reminder: 'Reminder',
  caseTransferred: 'CaseTransferred',
  invoiceSent: 'InvoiceSent',
  settled: 'Settled'
}

const DetailsSection = ({ details, countryCode, brandInfo }) => {
  const { t } = useTranslation()
  const [openKid, setOpenKid] = useState(false)
  const [openAccount, setOpenAccount] = useState(false)
  const [openIban, setOpenIban] = useState(false)
  const [openSwift, setOpenSwift] = useState(false)
  const [showCaseStatus, setShowCaseStatus] = useState(false)
  const [isActionButtonsVisible, setIsActionButtonsVisible] = useState(false)
  const [accountValue] = useState(
    details.paymentRecipient?.bankAccount
      ? details.paymentRecipient.bankAccount
      : t('common.undefined')
  )
  const [kidValue] = useState(
    details.paymentRecipient?.kid
      ? details.paymentRecipient.kid
      : t('common.undefined')
  )
  const [ibanValue] = useState(
    details.paymentRecipient?.bankAccount_IBAN
      ? details.paymentRecipient.bankAccount_IBAN
      : t('common.undefined')
  )

  const [swiftValue] = useState(
    details.paymentRecipient?.bankAccount_SWIFT
      ? details.paymentRecipient.bankAccount_SWIFT
      : t('common.undefined')
  )

  useEffect(() => {
    switch (details.status) {
      case caseStatus.open:
        setShowCaseStatus(false)
        break
      case caseStatus.invoice:
        setShowCaseStatus(false)
        break
      case caseStatus.closed:
        setShowCaseStatus(true)
        break
      case caseStatus.withdraw:
        setShowCaseStatus(true)
        break
      default:
        setShowCaseStatus(false)
        break
    }
  }, [details.status])

  const timeToCloseToolTip = 2000
  const navWithQuery = useNavWithQuery()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleKidCopy = () => {
    navigator.clipboard.writeText(kidValue)
    setOpenKid(true)
    setOpenAccount(false)
    setOpenIban(false)
    setOpenSwift(false)

    setTimeout(() => {
      setOpenKid(false)
    }, timeToCloseToolTip)
  }

  const handleAccountCopy = () => {
    navigator.clipboard.writeText(accountValue)
    setOpenAccount(true)
    setOpenKid(false)
    setOpenIban(false)
    setOpenSwift(false)

    setTimeout(() => {
      setOpenAccount(false)
    }, timeToCloseToolTip)
  }

  const handleIbanCopy = () => {
    navigator.clipboard.writeText(ibanValue)
    setOpenAccount(false)
    setOpenKid(false)
    setOpenIban(true)
    setOpenSwift(false)

    setTimeout(() => {
      setOpenIban(false)
    }, timeToCloseToolTip)
  }

  const handleSwiftCopy = () => {
    navigator.clipboard.writeText(swiftValue)
    setOpenAccount(false)
    setOpenKid(false)
    setOpenIban(false)
    setOpenSwift(true)

    setTimeout(() => {
      setOpenSwift(false)
    }, timeToCloseToolTip)
  }
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#2a3039'
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#2a3039',
      fontFamily: 'Montserrat'
    }
  }))

  const logout = () => {
    localStorage.removeItem('auth')
    navWithQuery('/')
  }

  const featureName = 'DEBTOR_INTERCOM_REPORTING_ACTIONS_ENABLE'
  const { isReady } = useContext(SplitContext)
  const treatments = useTreatments([featureName])

  useEffect(() => {
    if (treatments[featureName].treatment === 'on') {
      setIsActionButtonsVisible(true)
    } else {
      setIsActionButtonsVisible(false)
    }
  }, [treatments])

  const [showBasedOnCountry, setHideBasedOnCountry] = useState(false)

  useEffect(() => {
    switch (countryCode) {
      case 'FI':
        setHideBasedOnCountry(false)
        break
      case 'NO':
        setHideBasedOnCountry(true)
      default:
        setHideBasedOnCountry(true)
        break
    }
  }, [countryCode])

  if (showCaseStatus) {
    return (
      <CaseStatus
        status={details.status}
        state={details.state}
        transferredCaseNumber={details.transferredCaseNumber}
      />
    )
  }

  return (
    isReady && (
      <div className="container">
        <div className="details-wrapper">
          <div className="details">
            <div className="details__case-number">
              <div className="details__case-number--text">
                {t('details.title.case_number')}
              </div>
              <div className="details__case-number--number">
                {details.caseNo ? details.caseNo : t('common.undefined')}
              </div>
            </div>
            {isActionButtonsVisible && (
              <>
                <div className="invoice-buttons">
                  <StyledButton
                    color="green"
                    label={t('details.show_original_invoice')}
                    className="custom-default-button invoice-copy-button"
                    id="claim-action-button-request-invoice-copy"
                    onClick={() => {
                      const newSearchParams = new URLSearchParams(searchParams)
                      newSearchParams.set('action', 'invoicecopy')
                      setSearchParams(newSearchParams)
                      sa_event('click_request_invoice_copy')
                    }}
                  />
                </div>
              </>
            )}
            <div className="details__info--creditor-name">
              <div className="details__info--creditor-name-label">
                {t('details.creditor')}
              </div>
              <div className="details__info--creditor-name-value">
                {details.creditor.name
                  ? details.creditor.name
                  : t('common.undefined')}
              </div>
            </div>
            <div className="hr hr-desktop"></div>
            <div className="details__info">
              <div className="details__info--details">
                <div className="hr hr-mobile"></div>
                <div className="details__info--row">
                  <div className="details__info--title">
                    {t('details.title.details')}
                  </div>
                </div>
                <div className="details__info--row">
                  <div className="details__info--label">
                    {t('details.original_claim')}
                  </div>
                  <div className="details__info--value">
                    {details.originalAmount
                      ? formatMoney(details.originalAmount) + ' ,-'
                      : t('common.undefined')}
                  </div>
                </div>
                <div className="details__info--row">
                  <div className="details__info--label">{t('details.fee')}</div>
                  <div className="details__info--value">
                    {details.paymentsInfo?.fees
                      ? formatMoney(details.paymentsInfo.fees.total) + ' ,-'
                      : t('common.undefined')}
                  </div>
                </div>
                <div className="details__info--row">
                  <div className="details__info--label">
                    {t('details.interest')}
                  </div>
                  <div className="details__info--value">
                    {details.paymentsInfo?.interest
                      ? formatMoney(details.paymentsInfo.interest.total) + ' ,-'
                      : t('common.undefined')}
                  </div>
                </div>
                {details.paymentsInfo?.courtFees.total > 0 && (
                  <div className="details__info--row">
                    <div className="details__info--label">
                      {t('details.court_fees')}
                    </div>
                    <div className="details__info--value">
                      {details.paymentsInfo?.courtFees
                        ? formatMoney(details.paymentsInfo.courtFees.total) +
                          ' ,-'
                        : t('common.undefined')}
                    </div>
                  </div>
                )}
                {details.paymentsInfo?.others.total > 0 && (
                  <div className="details__info--row">
                    <div className="details__info--label">
                      {t('details.others')}
                    </div>
                    <div className="details__info--value">
                      {details.paymentsInfo?.others
                        ? formatMoney(details.paymentsInfo.others.total) + ' ,-'
                        : t('common.undefined')}
                    </div>
                  </div>
                )}
                <div className="details__info--row">
                  <div className="details__info--label">
                    {t('details.paid_until_now')}
                  </div>
                  <div className="details__info--value">
                    {details.paymentsInfo?.paymentSummary
                      ? formatMoney(details.paymentsInfo.paymentSummary.paid) +
                        ' ,-'
                      : t('common.undefined')}
                  </div>
                </div>
                <div className="details__info--row">
                  <div className="details__info--label">
                    {t('details.totally_remaining')}
                  </div>
                  <div className="details__info--totalValue">
                    <div className="details__info--value">
                      {details.paymentsInfo?.paymentSummary
                        ? formatMoney(details.paymentsInfo.paymentSummary.due) +
                          ' ' +
                          (details.currencyCode ? details.currencyCode : ',-')
                        : t('common.undefined')}
                    </div>
                  </div>
                </div>
              </div>

              <div className="details__info--payment">
                <div className="hr hr-mobile"></div>
                <div className="details__info--row">
                  <div className="details__info--title">
                    {t('details.title.payment')}
                  </div>
                </div>
                <div className="details__info--row">
                  <div className="details__info--label">
                    {t('details.due_date')}
                  </div>
                  <div className="details__info--value">
                    {details.dueDate
                      ? getDate(details.dueDate)
                      : t('common.undefined')}
                  </div>
                </div>
                <div className="details__info--row">
                  <div className="details__info--label">{t('details.kid')}</div>
                  <div
                    className="details__info__copy"
                    onClick={() => {
                      handleKidCopy()
                      sa_event('click_copy_payment_info')
                    }}
                  >
                    <div className="details__info--value">
                      {details.paymentRecipient?.kid
                        ? details.paymentRecipient.kid
                        : t('common.undefined')}
                    </div>
                    <CustomTooltip
                      PopperProps={{
                        disablePortal: true
                      }}
                      open={openKid}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                      title={t('common.copied')}
                      TransitionComponent={Zoom}
                      placement="top"
                    >
                      <div>
                        <Icons.CopyV2 className="details__info__copy--img" />
                      </div>
                    </CustomTooltip>
                  </div>
                </div>
                {showBasedOnCountry && (
                  <div className="details__info--row">
                    <div className="details__info--label">
                      {t('details.to_account')}
                    </div>
                    <div
                      className="details__info__copy"
                      onClick={() => {
                        handleAccountCopy()
                        sa_event('click_copy_payment_info')
                      }}
                    >
                      <div className="details__info--value">
                        {details.paymentRecipient?.bankAccount
                          ? details.paymentRecipient.bankAccount
                          : t('common.undefined')}
                      </div>
                      <CustomTooltip
                        PopperProps={{
                          disablePortal: true
                        }}
                        open={openAccount}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                        title={t('common.copied')}
                        TransitionComponent={Zoom}
                        placement="top"
                      >
                        <div>
                          <Icons.CopyV2 className="details__info__copy--img" />
                        </div>
                      </CustomTooltip>
                    </div>
                  </div>
                )}
                <div className="details__info--row">
                  <div className="details__info--label">
                    {t('details.iban')}
                  </div>
                  <div
                    className="details__info__copy"
                    onClick={() => {
                      handleIbanCopy()
                      sa_event('click_copy_payment_info')
                    }}
                  >
                    <div className="details__info--value">
                      {details.paymentRecipient?.bankAccount_IBAN
                        ? details.paymentRecipient.bankAccount_IBAN
                        : t('common.undefined')}
                    </div>
                    <CustomTooltip
                      PopperProps={{
                        disablePortal: true
                      }}
                      open={openIban}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                      title={t('common.copied')}
                      TransitionComponent={Zoom}
                      placement="top"
                    >
                      <div>
                        <Icons.CopyV2 className="details__info__copy--img" />
                      </div>
                    </CustomTooltip>
                  </div>
                </div>
                <div className="details__info--row">
                  <div className="details__info--label">
                    {t('details.swift')}
                  </div>
                  <div
                    className="details__info__copy"
                    onClick={() => {
                      handleSwiftCopy()
                      sa_event('click_copy_payment_info')
                    }}
                  >
                    <div className="details__info--value">
                      {details.paymentRecipient?.bankAccount_SWIFT
                        ? details.paymentRecipient.bankAccount_SWIFT
                        : t('common.undefined')}
                    </div>
                    <CustomTooltip
                      PopperProps={{
                        disablePortal: true
                      }}
                      open={openSwift}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                      title={t('common.copied')}
                      TransitionComponent={Zoom}
                      placement="top"
                    >
                      <div>
                        <Icons.CopyV2 className="details__info__copy--img" />
                      </div>
                    </CustomTooltip>
                  </div>
                </div>
              </div>
            </div>
            {isActionButtonsVisible && (
              <ActionButtons useSearchParams={useSearchParams} {...details} />
            )}
            <div className="new-case-button">
              <StyledButton
                onClick={() => {
                  logout()
                  sa_event('click_find_another_case')
                }}
                className="custom-default-button"
                label={t('common.another_case_button')}
                color="orange"
                customColor={brandInfo?.action_button_color}
              />
            </div>
          </div>
        </div>
      </div>
    )
  )
}
export default DetailsSection
