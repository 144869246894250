import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckmarkCircle, LinkOutline } from 'react-ionicons'
import { HelpModal, Modal, StyledButton } from 'ui'
import { GET_HELP_POPUP_ITEMS } from '../data/cms/HelpPopup/HelpPopupItems'
import { useContent } from '../hooks/cms/use-content'
import useNavWithQuery from '../hooks/navigation/useNavWithQuery'
import { caseState, caseStatus } from './DetailsSection'

const CaseStatus = ({ status, state, transferredCaseNumber }) => {
  const { t, i18n } = useTranslation()
  const navWithQuery = useNavWithQuery()
  const [header, setHeader] = useState()
  const [description, setDescription] = useState()
  const [showCaseTransferred, setShowCaseTransferred] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)

  useEffect(() => {
    switch (status) {
      case caseStatus.closed:
        setHeader('details.case_status.header_closed')
        setDescription('details.case_status.closed')
        break
      case caseStatus.withdraw:
        setHeader('details.case_status.header_withdrawn')
        setDescription('details.case_status.withdrawn')
        break
    }
  }, [status])

  useEffect(() => {
    switch (state) {
      case caseState.caseTransferred:
        setHeader('details.case_status.header_merged')
        setDescription('details.case_status.merged')
        setShowCaseTransferred(true)
        break
      case caseState.settled:
        setHeader('details.case_status.header_finished_and_paid')
        setDescription('details.case_status.finished_and_paid')
        break
      default:
        setShowCaseTransferred(false)
        break
    }
  }, [state])

  const logout = () => {
    localStorage.removeItem('auth')
    navWithQuery('/')
  }

  const goToTransferredCase = () => {
    navWithQuery(`/case/${transferredCaseNumber}`)
  }

  const slug = 'case/case-transferred'

  const helpPopupData = useContent(GET_HELP_POPUP_ITEMS, {
    language: i18n.language,
    slug: 'case/case-transferred'
  })

  const isLoading = helpPopupData.isLoading || !helpPopupData.data

  useEffect(() => {
    !isLoading && helpPopupData.refetch()
  }, [i18n.language])

  if (showCaseTransferred) {
    return (
      <>
        {modalOpened && (
          <>
            {!isLoading && (
              <Modal
                animation="slide-up"
                key="confirmation_modal"
                position="center-center"
                onClose={() => {
                  setModalOpened(null)
                }}
                backdropColor={'rgba(65,71,155,0.6'}
              >
                <HelpModal
                  onClose={() => {
                    setModalOpened(null)
                  }}
                  data={helpPopupData?.data}
                  type={'warning'}
                />
              </Modal>
            )}
          </>
        )}
        <div className="case-status__wrapper">
          <div className="case-status">
            <h1 className="case-status__header">{t(header)}</h1>
            <div className="hr"></div>
            <p className="case-status__description">{t(description)}</p>
            <div
              className="case-status__linkToTransferred"
              onClick={goToTransferredCase}
            >
              <div className="case-status__linkIcon">
                <LinkOutline
                  color={'#00000'}
                  title={'linkIcon'}
                  height=""
                  width=""
                />
              </div>
              <div>{'#' + transferredCaseNumber}</div>
            </div>
          </div>
          <div className="case-status__helpModal">
            <div
              className="case-status__helpModal--title"
              onClick={() => {
                setModalOpened(true)
                sa_event(`open_inline_help`, { slug: slug })
              }}
            >
              {t('details.case_status.help_header')}
            </div>
          </div>
          <div className="new-case-button">
            <StyledButton
              onClick={logout}
              className="custom-default-button"
              label={t('common.another_case_button')}
              color="orange"
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="case-status__wrapper">
      <div className="case-status">
        <h1 className="case-status__header">{t(header)}</h1>
        <div className="hr"></div>
        <p className="case-status__description">{t(description)}</p>
        <div className="case-status__checkMark">
          <CheckmarkCircle
            color={'#59BE84'}
            title={'checkMark'}
            height=""
            width=""
          />
        </div>
      </div>
      <div className="new-case-button">
        <StyledButton
          onClick={logout}
          className="custom-default-button"
          label={t('common.another_case_button')}
          color="orange"
        />
      </div>
    </div>
  )
}

export default CaseStatus
