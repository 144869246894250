import React, { useEffect } from 'react'
import { GET_QUESTIONS_ITEMS } from '../data/cms/Questions/QuestionItems'
import { useTranslation } from 'react-i18next'
import { useContent } from '../hooks/cms/use-content'
import { QuestionBlock } from '../components/QuestionBlock'
import { setBodyColor } from '../utils/setBodyColor'
import Loader from '../components/Loader'

const Questions = () => {
  useEffect(() => {
    setBodyColor({ color: '#ffffff' })
  }, [])
  const { t, i18n } = useTranslation()

  const { data: questions, isLoading } = useContent(GET_QUESTIONS_ITEMS, {
    language: i18n.language
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className="container">
      <div className="questions">
        <div className="questions--title">{t('questions.title')}</div>
      </div>
      <QuestionBlock data={questions} />
    </div>
  )
}

export default Questions
