import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { useEffect, useState, useMemo } from 'react'
import { environment as defaultEnvironment } from '../constants/environment'

let configCache = {}

const useConfig = () => {
  const [config, setConfig] = useState(null)
  const isLoaded = useMemo(() => config != null && !isEmpty(config), [config])

  const getEnvironment = () => {
    return axios
      .get('/environment.json')
      .then(({ data }) =>
        typeof data === 'object' ? data : defaultEnvironment
      )
      .catch((_) => defaultEnvironment)
  }

  useEffect(() => {
    if (!isEmpty(configCache)) {
      setConfig(configCache)
    } else {
      getEnvironment().then((environment) => {
        configCache = { ...environment }
        setConfig(environment)
      })
    }
  }, [])

  return [isLoaded, config, setConfig]
}

export default useConfig
