import { lighten } from 'polished'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pictures, StyledButton } from 'ui'
import useNavWithQuery from '../hooks/navigation/useNavWithQuery'
import ChangeLanguage from './ChangeLanguage'

const Header = ({ brandInfo }) => {
  const { t } = useTranslation()
  const [isBurgerOpened, setIsBurgerOpened] = useState(false)
  const navWithQuery = useNavWithQuery()

  const customNavigate = (path) => {
    setIsBurgerOpened(false)
    navWithQuery(path)
  }

  return (
    <header
      className="header"
      data-is-open={isBurgerOpened}
      style={{ '--header-background-color': brandInfo?.primary_color }}
    >
      <div className="header__content">
        <div className="header__logo" onClick={() => navWithQuery('/')}>
          {brandInfo?.logo?.filename ? (
            <img
              src={brandInfo.logo.filename}
              className="header__logo--img"
              alt={brandInfo.logo.alt ?? ''}
            />
          ) : (
            <Pictures.WhiteKraviaLogo className="header__logo--img" />
          )}
        </div>
        <div className="header__menu" data-is-open={isBurgerOpened}>
          <div className="header__links">
            <a
              className="header__menu-item"
              onClick={() => customNavigate('/questions')}
              style={{
                '--header-button-color':
                  brandInfo?.primary_color &&
                  lighten(0.1, brandInfo.primary_color),
                '--header-button-hover-color': brandInfo?.primary_color
              }}
            >
              {t('header.common_questions')}
            </a>
            <a
              className="header__menu-item header__menu-item-mobile"
              onClick={() => customNavigate('/privacy-policy')}
            >
              {t('header.privacy_policy')}
            </a>
            <StyledButton
              style={{ marginTop: '24px' }}
              onClick={() => customNavigate('/')}
              className="action-button__title header__find-case-button"
              label={t('header.find_case')}
              color="orange"
            />
          </div>
          <ChangeLanguage data-is-open={isBurgerOpened} />
        </div>
        <button
          className="header__burger-button"
          data-is-open={isBurgerOpened}
          onClick={() => setIsBurgerOpened(!isBurgerOpened)}
        >
          <div className="header__burger-icon"></div>
        </button>
      </div>
    </header>
  )
}

export default Header
