export const getDefaultCountryCode = (language) => {
  switch (language) {
    case 'no':
      return 'NO'
    case 'fi':
      return 'FI'
    case 'sv':
      return 'SE'
    default:
      return 'NO'
  }
}
