import * as yup from 'yup'

import i18n from '../locales/i18n'

export const caseNumberSchemas = yup.object().shape({
  caseNumber: yup
    .string()
    .required('validation.case_number.empty')
    .matches(
      /^[0-9]+[0-9\-][0-9]+$/,
      'validation.case_number.must_be_digits_with_suffix'
    )
    .min(3, 'validation.case_number.length'),
  countryCode: yup.string().required()
})

export const zipCodeSchemas = yup.object().shape({
  zipCode: yup
    .string()
    .required('validation.zip_code.empty')
    .matches(
      /^[A-Za-z0-9][A-Za-z0-9\- ]{0,10}[A-Za-z0-9]$/,
      'validation.zip_code.requirements'
    )
    .min(4, 'validation.zip_code.length')
})
