import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import {
  CountrySelector,
  HelpModal,
  Icons,
  InputFields,
  InputWrapper,
  Modal,
  StyledButton
} from 'ui'

import { useMemo } from 'react'
import styled from 'styled-components'
import { caseNumberSchemas } from '../constants/validation-schemas'
import { GET_HELP_POPUP_ITEMS } from '../data/cms/HelpPopup/HelpPopupItems'
import { useContent } from '../hooks/cms/use-content'
import useNavWithQuery from '../hooks/navigation/useNavWithQuery'
import checkError from '../utils/checkError'
import { getDefaultCountryCode } from '../utils/country'
import { removeSuffix } from '../utils/removeSuffix'
import { setBodyColor } from '../utils/setBodyColor'

const Login = ({ brandInfo, isBrandFetched }) => {
  useEffect(() => {
    if (isBrandFetched) {
      setBodyColor({ color: brandInfo?.background_color ?? '#e4e3f2' })
    }
  }, [isBrandFetched])
  const { t, i18n } = useTranslation()
  const navWithQuery = useNavWithQuery()

  const [submitting, setSubmitting] = useState(false)
  const [authError, setAuthError] = useState(null)
  const [searchParams] = useSearchParams()
  const [caseNotFound] = useState(searchParams.get('caseNotFound'))
  const [modalOpened, setModalOpened] = useState(null)
  const [selectedContactCountry, setSelectedContactCountry] = useState(
    getDefaultCountryCode(i18n.language)
  )

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: { countryCode: getDefaultCountryCode(i18n.language) },
    resolver: yupResolver(caseNumberSchemas)
  })

  useEffect(() => {
    reset({ countryCode: getDefaultCountryCode(i18n.language) })
    setSelectedContactCountry(getDefaultCountryCode(i18n.language))
  }, [i18n.language, reset])

  useEffect(() => {
    if (caseNotFound === 'true') {
      setAuthError('errors.general.not_found')
    } else {
      setAuthError(null)
    }
  }, [caseNotFound])

  const onSubmit = async (data) => {
    sa_event('click_next_login')
    setSubmitting(true)
    try {
      const response = await axios.post(`/authorize`, {
        username: removeSuffix(data.caseNumber),
        operationCountryCode: data.countryCode
      })
      if (response.data.successful === true) {
        navWithQuery(`verify/${data.caseNumber}`, {
          params: {
            countryCode: data.countryCode,
            link: false
          }
        })
      }
    } catch (error) {
      console.log(error.response)
      setAuthError(checkError(error.response.data.errorType))
    } finally {
      setSubmitting(false)
    }
  }

  const caseNumberWorkData = useContent(GET_HELP_POPUP_ITEMS, {
    language: i18n.language,
    slug: 'login/case-number-doesn-t-work'
  })

  const FindCaseNumberData = useContent(GET_HELP_POPUP_ITEMS, {
    language: i18n.language,
    slug: 'login/where-do-i-find-my-case-number'
  })

  const contactInfo = useMemo(() => {
    const contact = brandInfo?.contacts?.find(
      (contact) =>
        contact.content.Country.toUpperCase() ===
        selectedContactCountry.toUpperCase()
    )
    return contact?.content
  }, [brandInfo, selectedContactCountry])

  const availableContactCountries = useMemo(() => {
    const countries = brandInfo?.contacts?.map((contact) =>
      contact.content.Country.toUpperCase()
    )
    return countries
  }, [brandInfo])

  const helpPopupData =
    modalOpened == 'where_to_find_case_data'
      ? FindCaseNumberData
      : caseNumberWorkData

  const isLoading = helpPopupData.isLoading || !helpPopupData.data

  useEffect(() => {
    !isLoading && helpPopupData.refetch()
  }, [i18n.language])

  return (
    <div className="login-wrapper">
      {modalOpened && (
        <>
          {!isLoading && (
            <Modal
              animation="slide-up"
              key="confirmation_modal"
              position="center-center"
              onClose={() => {
                setModalOpened(null)
              }}
              backdropColor={'rgba(65,71,155,0.4'}
            >
              <HelpModal
                onClose={() => {
                  setModalOpened(null)
                }}
                data={helpPopupData.data}
                type={'warning'}
              />
            </Modal>
          )}
        </>
      )}
      <div className="login-box">
        <div className="login-box__wrapper">
          <div>
            <div className="login-box__section">
              <h1 className="login-box__title">{t('login.title')}👋</h1>
              <p className="login-box__description">{t('login.description')}</p>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="loginForm"
                id="form"
              >
                <fieldset disabled={submitting} className="fieldset">
                  <div
                    className={
                      errors.caseNumber
                        ? 'login-box__input-wrapper errorInput'
                        : 'login-box__input-wrapper'
                    }
                  >
                    <div className="login-box__country-selector">
                      <CountrySelector
                        availableCountries={['NO', 'FI', 'SE']}
                        selectedCountry={watch('countryCode')}
                        onChange={(value) => {
                          setValue('countryCode', value)
                        }}
                      />
                    </div>
                    <InputWrapper>
                      <InputFields
                        type="text"
                        name="caseNumber"
                        id="caseNumber"
                        {...register('caseNumber', {
                          onChange: () => setAuthError(null)
                        })}
                        handleChange={register('caseNumber').onChange}
                        value={watch('caseNumber') ?? ''}
                        mask="digits_and_hyphen"
                        className={'login-box__input errorInput'}
                        placeholder={t('login.input_placeholder')}
                        onFocus={(e) => (e.target.placeholder = '')}
                        onBlur={(e) =>
                          (e.target.placeholder = t('login.input_placeholder'))
                        }
                      />
                    </InputWrapper>
                  </div>

                  {authError && <p className="error-label">{t(authError)}</p>}
                  {errors.caseNumber && (
                    <p className="error-label">
                      {t(errors.caseNumber.message)}
                    </p>
                  )}
                </fieldset>
              </form>
            </div>
            <div className="button-groupe action-button__login">
              <div className="action-button action-button__login">
                <StyledButton
                  className="action-button__title action-button__login"
                  label={t('common.next_button')}
                  type="submit"
                  form="form"
                  color="orange"
                  customColor={brandInfo?.action_button_color}
                  size="large"
                  loading={submitting}
                />
              </div>
            </div>
            <div className="help-section">
              <div className="help-section__header">{t('help.header')}</div>
              <div
                className="help-section__list"
                onClick={() => {
                  setModalOpened('where_to_find_case_data')
                  sa_event(`open_inline_help`, { slug: slug })
                }}
              >
                <Icons.Arrow width={14} style={{ marginRight: '14px' }} />{' '}
                <span className="help-section__list-text">
                  {t('help.where_to_find_case_data')}
                </span>
              </div>
              <div
                className="help-section__list"
                onClick={() => {
                  setModalOpened('case_number_not_working')
                  sa_event(`open_inline_help`, { slug: slug })
                }}
              >
                <Icons.Arrow width={14} style={{ marginRight: '14px' }} />{' '}
                <span className="help-section__list-text">
                  {t('help.case_number_not_working')}
                </span>
              </div>
            </div>
          </div>
        </div>
        {availableContactCountries?.length !== 0 && (
          <ContactSection brandInfo={brandInfo}>
            <PhoneNumberSection>
              <CountrySelectorWrapper brandInfo={brandInfo}>
                <CountrySelector
                  availableCountries={availableContactCountries}
                  selectedCountry={selectedContactCountry}
                  onChange={(value) => {
                    setSelectedContactCountry(value)
                  }}
                />
              </CountrySelectorWrapper>
              <PhoneNumber>{contactInfo?.Phone}</PhoneNumber>
            </PhoneNumberSection>
            <OpeningHoursSection>
              {contactInfo?.OpeningHours}
            </OpeningHoursSection>
          </ContactSection>
        )}
      </div>
    </div>
  )
}

export default Login

const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${({ brandInfo }) =>
    brandInfo?.contact_background_color ?? '#41479B'};
  color: ${({ brandInfo }) => brandInfo?.contact_text_color ?? '#ffffff'};
  padding: 16px 40px;
  gap: 8px;

  @media (min-width: 775px) {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    flex-direction: row;
    padding: 24px 40px;
  }
`

const CountrySelectorWrapper = styled.div`
  & > div {
    min-width: unset;
  }

  button {
    color: ${({ brandInfo }) => brandInfo?.contact_text_color};
    padding: 0;
    svg {
      &:first-child {
        width: 34px;
        border-radius: 3px;
      }

      &:last-child {
        width: 15px;
        margin-left: 10px;
      }
    }

    div {
      display: none;
    }
  }
`

const PhoneNumberSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const OpeningHoursSection = styled.div`
  font-size: 14px;
  font-weight: 400;
  @media (min-width: 775px) {
    font-size: 16px;
  }
`

const PhoneNumber = styled.div`
  font-size: 16px;
  font-weight: 600;
  @media (min-width: 775px) {
    font-size: 18px;
  }
`
