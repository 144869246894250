import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import DetailsSection from '../components/DetailsSection'
import Error from '../components/Error'
import Loader from '../components/Loader'
import AuthContext from '../context/AuthContext'
import useNavWithQuery from '../hooks/navigation/useNavWithQuery'
import checkError from '../utils/checkError'
import { getDefaultCountryCode } from '../utils/country'
import { setBodyColor } from '../utils/setBodyColor'

const Case = ({ brandInfo }) => {
  useEffect(() => {
    setBodyColor({ color: '#ffffff' })
  }, [])

  const { t } = useTranslation()
  const [details, setDetails] = useState([])
  const [showLoader, setShowLoader] = useState(true)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const { id } = useParams()
  const { boot, shutdown } = useIntercom()
  const [auth] = useContext(AuthContext)
  const navWithQuery = useNavWithQuery()
  const location = useLocation()

  const [searchParams] = useSearchParams()
  const country = searchParams.get('countryCode') || getDefaultCountryCode()

  useEffect(() => {
    document.title = t('common.title_case')
  }, [t])

  useEffect(() => {
    shutdown()
    if (!auth) {
      navWithQuery('/')
    }
  }, [auth])

  useEffect(() => {
    const getDetails = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth} `
          }
        }
        const response = await axios.get(
          `/claim/${id}?operationCountryCode=${country}`,
          config
        )
        setDetails(response.data.value)
      } catch (error) {
        setError(true)
        setErrorMessage(checkError(error.response.data.errorType))
      } finally {
        setShowLoader(false)
        boot()
      }
    }

    if (auth) {
      getDetails()
    }
  }, [location])

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <>
          {error ? (
            <Error errorMessage={errorMessage} />
          ) : (
            <DetailsSection
              details={details}
              brandInfo={brandInfo}
              countryCode={country}
            />
          )}
        </>
      )}
    </>
  )
}

export default Case
