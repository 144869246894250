import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { PrivacyPolicyStandalone } from 'ui'
import { useContent } from '../hooks/cms/use-content'

import Loader from '../components/Loader'

export const PRIVACY_POLICY = `
    query {    
        LegaldocumentItem(id: "$language/legal-documents/privacy-policy/generic") {        
            content {
                title,
                text
            }
        }
    }
`

const PrivacyPolicy = () => {
  const { i18n } = useTranslation()

  const privacyPolicy = useContent(PRIVACY_POLICY, {
    language: i18n.language
  })

  if (!privacyPolicy.data) {
    return <Loader />
  }

  return (
    <Wrapper>
      <PrivacyPolicyStandalone
        title={privacyPolicy?.data.content.title}
        data={privacyPolicy?.data.content.text}
      />
    </Wrapper>
  )
}

export default PrivacyPolicy

const Wrapper = styled.div`
  background-color: #ffffff;
  padding-top: 100px;
  padding-bottom: 48px;

  ol,
  ul {
    padding: 0;
    margin: 0;
  }
`
