export const GET_QUESTIONS_ITEMS = `
query {
  QuestionItems(starts_with: "$language/knowledge-base/faq",
  filter_query: {
      country: {in_array: "no"},
      audience: { in_array: "debtor"}
  }) {
      items {
          full_slug,
          content {
              question,
              answer,
              audience
          }
      }
      
  }
}
`
