export const GET_HELP_POPUP_ITEMS = `
query {
    HelppopupItem(id: "$language/help-popup/debtor-page/$slug") {
        full_slug,
        content {
            icon {
                filename
            }
            title,
            description,
            accordion,
            links_title,
            links

        }
    }
}
`
