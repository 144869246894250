import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledButton } from 'ui'
import useNavWithQuery from '../hooks/navigation/useNavWithQuery'

const Error = ({ errorMessage }) => {
  const { t } = useTranslation()
  const navWithQuery = useNavWithQuery()

  const goBack = () => {
    navWithQuery('/')
  }
  return (
    <div className="error">
      <div className="error__box">
        <span className="error__box--text">{t(errorMessage)}</span>
        <StyledButton
          onClick={goBack}
          color="orange"
          label={t('common.back_button')}
          className="error__box--button"
        ></StyledButton>
      </div>
    </div>
  )
}

export default Error
