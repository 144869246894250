import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import './locales/i18n'
import './scss/style.scss'

import styled from 'styled-components'
import { Spinner } from 'ui'
import Footer from './components/Footer'
import Header from './components/Header'
import { GET_BRAND_ITEMS } from './data/cms/Brand/BrandItems'
import { useContent } from './hooks/cms/use-content'
import Case from './pages/Case'
import Login from './pages/Login'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Questions from './pages/Questions'
import Verify from './pages/Verify'
import withProviders from './providers/WithProviders'

function App() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const brandQuery = searchParams.get('brand')
  const hostnameParts = window.location.hostname.split('.')
  const subDomain =
    hostnameParts.length > 2 ? hostnameParts.slice(0, -2).join('.') : ''

  document.title = t('common.title')
  const { boot } = useIntercom()

  useEffect(() => {
    boot()
  }, [])

  const { data: brand, isFetched: isBrandFetched } = useContent(
    GET_BRAND_ITEMS,
    {
      language: 'no'
    }
  )

  const brandInfo = useMemo(() => {
    let brandData = subDomain
      ? brand?.items?.find(
          (items) =>
            items.content.brand.toUpperCase() === subDomain.toUpperCase()
        )
      : null

    if (!brandData && brandQuery) {
      brandData = brand?.items?.find(
        (items) =>
          items.content.brand.toUpperCase() === brandQuery.toUpperCase()
      )
    }

    if (!brandData) {
      brandData = brand?.items?.find(
        (items) => items.content.brand.toUpperCase() === 'KRAVIA'
      )
    }

    return brandData?.content
  }, [brand, brandQuery, subDomain])

  if (!isBrandFetched) {
    return (
      <SpinnerWrapper>
        <Spinner size={68} />
      </SpinnerWrapper>
    )
  }

  return (
    <>
      <Header brandInfo={brandInfo} />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Login brandInfo={brandInfo} isBrandFetched={isBrandFetched} />
          }
        />
        <Route
          exact
          path="/verify/:id"
          element={
            <Verify brandInfo={brandInfo} isBrandFetched={isBrandFetched} />
          }
        />
        <Route
          exact
          path="/case/:id"
          element={<Case brandInfo={brandInfo} />}
        />
        <Route exact path="/questions" element={<Questions />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Login />} />
      </Routes>
      <Footer />
    </>
  )
}

export default withProviders(App)

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
