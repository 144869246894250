import { useQuery } from 'react-query'

import useConfig from '../useConfig'

import { client } from '../../data/cms/client'

export const useContent = (query, variables, options) => {
  const [isConfigLoaded, config] = useConfig()

  const { data, isLoading, isFetched, isError, refetch } = useQuery(
    [query, variables, options],
    () =>
      client.query(query, {
        variables,
        url: config?.storyblokConfig.url,
        token: config?.storyblokConfig.token,
        version: config?.storyblokConfig.version
      }),
    {
      enabled: isConfigLoaded,
      retry: false,
      staleTime: 5 * 60 * 1000, // Data caching time in milliseAconds
      context: window.ReactQueryClientContext,
      ...options
    }
  )

  return { data, isLoading, isFetched, isError, refetch }
}
